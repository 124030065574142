

import { Button } from '@/Components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/Components/ui/select";
import { Separator } from '@/Components/ui/separator';
import { navigate, setLoader } from '@/Hooks/Functions';
import { JSONGetRequest } from '@/Hooks/JSONRequest';
import { Icon } from '@iconify/react';
import { usePage } from '@inertiajs/react';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { ConfirmDialog } from "@/lib/mixins";

export default function LeonardoAccountSelector({ account, onAccountChange, className = "" }) {
    const { auth, accounts } = usePage().props;

    const [selectedAccount, setSelectedAccount] = useState(account);
    const [credits, setCredits] = useState(auth?.user?.leonardo_credits || 0);

    useEffect(() => {
        if (selectedAccount === 'connect') {
            ConfirmDialog.fire({
                title: 'Connect Your Own Leonardo.Ai Account',
                text: "You're about to be directed to the configuration page. Do you want to proceed?",
                confirmButtonText: 'Yes Proceed.',
                allowOutsideClick: false
            }).then((result) => {
                result.isConfirmed && navigate(route('dashboard'));
                result.isDismissed && setAccount(account);
            })
        }
        else if (selectedAccount === 'rockmyprompt') {
            setCredits(auth?.user?.leonardo_credits || 0);
            onAccountChange(selectedAccount);
        } else if (!!selectedAccount) {
            JSONGetRequest({
                href: route('integration.leonardo-credits', { id: selectedAccount }),
                onResult: (res) => {
                    setCredits(res?.api_credits || 0);
                    onAccountChange(selectedAccount);
                },
                onLoading: (v) => setLoader(v)
            })
        }
    }, [selectedAccount, account])

    return <div className={cn('flex flex-row gap-x-2 items-center pr-4', className)}>
        <Select className="flex-1" value={selectedAccount} onValueChange={(v) => setSelectedAccount(v)} >
            <SelectTrigger className="rounded-full">
                <SelectValue placeholder="Select account" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectLabel>Select Account To Use</SelectLabel>
                    {accounts.map((acc) => <SelectItem key={acc.id} value={acc.id}>{acc.name}</SelectItem>)}
                </SelectGroup>
                <Separator className='h-[2px] my-2 w-full bg-primary' />
                <SelectGroup>
                    <SelectLabel>Connect your Own Leonardo.Ai account</SelectLabel>
                    <SelectItem value="connect">Connect Account</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
        <Popover>
            <PopoverTrigger asChild>
                <div title='Leonardo API Remaining Credits' className='px-4 py-1 bg-white cursor-pointer ml-auto border border-primary inline-flex gap-x-2 flex-nowrap items-center rounded-full'>
                    <Icon icon="healthicons:coins" className='text-lg' />
                    <span className='text-sm font-semibold'>{credits}</span>
                    <span className='text-xs font-semibold'>Credits</span>
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-80 flex flex-col gap-4">
                <p className='text-sm'> Enhance your design experience by purchasing API credits for Leonardo through our app. We've simplified the process by setting up a Leonardo account for you. Just buy API credits, and you're ready to go! </p>
                <Button onClick={() => navigate(route('dashboard'))} variant="theme" size="sm" className="block">Buy Credits</Button>
            </PopoverContent>
        </Popover>
    </div>
}
